import React, { Fragment } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "../../stylesheets/main.scss"

export default (props) => (
  <StaticQuery
    query={graphql`
      query BlankSiteQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => <Layout data={data} {...props} />}
  />
)

const Layout = (props) => {
  // Define the meta title and description
  const title = props.data.site.siteMetadata.title
  const description = props.data.site.siteMetadata.description

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <main role="main">{props.children}</main>
    </Fragment>
  )
}
