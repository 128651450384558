import React from "react"
import { Helmet } from "react-helmet"

import { Link } from "gatsby"
import Layout from "../components/layouts/BlankLayout"
import { IconClose, IconArrowRight, IconShop } from "../components/svg"
import { IconButtonLink } from "../components/buttons"
import Img from "gatsby-image"

export default ({ pageContext, location }) => {
  const { meta } = pageContext
  let offset

  if (location.state) {
    offset = location.state.offset
  }

  return (
    <Layout>
      <Helmet>
        <title>{pageContext.item.caption}</title>
        <meta name="description" content={pageContext.item.caption} />
      </Helmet>
      <div className="modal">
        <div className="modal__inner [ container -pinched ]">
          <div className="modal-actions">
            <div className="modal-navigation">
              <Link
                className={`icon-button -small -border-none ${
                  pageContext.prevLink.hash ? "" : "-disabled"
                }`}
                to={`/shop-my-style/${pageContext.prevLink.hash}`}
              >
                <IconArrowRight className="icon -flip icon--navigation-close" />
              </Link>
              {pageContext.nextLink && (
                <Link
                  className={`icon-button -small -border-none ${
                    pageContext.nextLink.hash ? "" : "-disabled"
                  }`}
                  to={`/shop-my-style/${pageContext.nextLink.hash}`}
                >
                  <IconArrowRight className="icon icon--navigation-close" />
                </Link>
              )}
            </div>
            <div className="modal-close">
              <IconButtonLink
                className="-small -border-none"
                to={`/shop-my-style`}
                state={{ offset }}
              >
                <IconClose className="icon icon--navigation-close" />
              </IconButtonLink>
            </div>
          </div>
          <div className="modal-profile">
            <Img fixed={meta.avatar_url.childImageSharp.fixed} loading="lazy" />
            <p className="modal-profile__name">{meta.blog_name}</p>
          </div>
          <div className="modal-content">
            <div className="modal-media">
              <div className="modal-image">
                {/* <Img
                  fixed={pageContext.item.hero_image.childImageSharp.fixed}
                  alt={meta.full_name}
                  loading="lazy"
                  style={{ width: "100%" }}
                /> */}
                <img
                  src={pageContext.item.hero_image.publicURL}
                  alt={meta.full_name}
                />
              </div>
              <div className="modal-navigation">
                <Link
                  className={`icon-button -small -border-none ${
                    pageContext.prevLink.hash ? "" : "-disabled"
                  }`}
                  to={`/shop-my-style/${pageContext.prevLink.hash}`}
                >
                  <IconArrowRight className="icon -flip icon--navigation-close" />
                </Link>
                {pageContext.nextLink && (
                  <Link
                    className={`icon-button -small -border-none ${
                      pageContext.nextLink.hash ? "" : "-disabled"
                    }`}
                    to={`/shop-my-style/${pageContext.nextLink.hash}`}
                  >
                    <IconArrowRight className="icon icon--navigation-close" />
                  </Link>
                )}
              </div>
            </div>
            <div className="modal-description">
              <div className="modal-caption">
                <p>{pageContext.item.caption}</p>
              </div>
              <p className="modal-shop-cta">Shop</p>
              <div className="modal-products">
                {pageContext.item.products.map((product, index) => {
                  return (
                    <div className="product" key={index}>
                      <a
                        className="product__link"
                        href={product.hyperlink}
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        <div className="product__inner">
                          <img src={product.image_url} alt="" loading="lazy" />
                          <div className="product__overlay">
                            <div className="overlay-icon">
                              <IconShop className="icon icon--shop" />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
